import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigConstants } from 'config-constants';
import { UtilsService } from 'projects/fantasy-shared/src/services';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private utilsService: UtilsService, private router: Router) { }

  // WalletStore
  private userWallet = new BehaviorSubject(null);
  walletObserver = this.userWallet.asObservable();

  private unseenNotifications = new BehaviorSubject(false);
  unseenNotificationsObserver = this.unseenNotifications.asObservable();

  private notificationCenter = new BehaviorSubject(null);
  notificationObserver = this.notificationCenter.asObservable();

  updateNotficationData(notifications) {
    let flag = false;
    notifications.map(it => {
      if (it.seenAt === '0001-01-01T00:00:00Z') flag = true;
    })
    this.updateUnseenNotifications(flag)
    this.notificationCenter.next(notifications)
  }

  updateUnseenNotifications(update = false) {
    this.unseenNotifications.next(update)
  }

  updateWalletOnAction(balance) {
    this.userWallet.next(balance);
  }

  getNotificationCenterData() {
    const url = 'notification/recent?limit=10'
    return this.http.get(ConfigConstants.API_URL_V2 + url).subscribe(res => {
      if (!res['data']) return;
      this.updateNotficationData(res['data'])
    })
  }

  registerClick(uuid) {
    const url = `register/cta?uuid=${uuid}`
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getNotificationById(notId) {
    const url = 'notification/historical?notificationId=' + notId;
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  changePassword(newPassword) {
    const url = 'players/password/change'
    return this.http.put(ConfigConstants.API_URL_V2 + url, newPassword)
  }

  getPayoutAmountsByGameId(gameId, roundId) {
    const url = 'payoutamounts?gameId=' + gameId + '&roundId=' + roundId
    return this.http.get(ConfigConstants.API_URL_V3 + url)
  }

  getPayoutAmountsByLbId(gameId, roundId, lbId) {
    const url = 'payoutamounts?gameId=' + gameId + '&roundId=' + roundId + "&leaderboardId=" + lbId
    return this.http.get(ConfigConstants.API_URL_V3 + url)
  }

  getDailyPayoutAmountsByRoundId() {
    const url = 'dailypayouts/templates';
    return this.http.get(ConfigConstants.API_URL_V3 + url)
  }


  getTickets(gameids, showSelections=false) {
    const queryParams = this.utilsService.encodeQueryParam("gameId", gameids)
    const hasToken = localStorage.getItem('JWT_TOKEN')
    let url = `players/tickets?${queryParams}` + '&ignoreRefunded=false&showSelections=' + showSelections
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const sesionString = sessionId ? `&sessionId=${sessionId}` : ''
      return this.http.get(ConfigConstants.API_URL_V3 + url + sesionString)
    } else {
      return this.http.get(ConfigConstants.API_URL_V2 + url);
    }
  }

  getPlayerDetails(playerId: string) {
    const url = 'players/' + playerId;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getPlayerNotifications(playerId) {
    const currentTime = (new Date()).toISOString();
    const urlname = 'players/' + playerId + '/notifications?seen=false&expiresAfter=' + currentTime
    return this.http.get(ConfigConstants.API_URL_V2 + urlname)
  }

  seenPlayerNotification(playerId, notificationId) {
    const urlname = 'players/' + playerId + '/notifications?notificationId=' + notificationId
    return this.http.put(ConfigConstants.API_URL_V2 + urlname, {})
  }

  updatePlayerDetails(playerId, playerDetails) {
    let url = ConfigConstants.API_URL_V2 + 'players/' + playerId
    return this.http.put(url, playerDetails)
  }

  getCountryFlagImage(countryName) {
    return this.http.get("https://countryflagsapi.com/svg/" + countryName)
  }


  createCheckout(gameId: number, roundId: number, returnUrl = '') {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/checkout'
    const hasToken = localStorage.getItem('JWT_TOKEN')
    // const routeName = 'partner/' + localStorage.getItem('AFF_NAME')
    if (!hasToken) {
      console.log("No token")
      // this.router.navigate([routeName], { queryParams: { returnUrl } })
    } else {
      return this.http.post(url, {
        gameIds: [gameId], roundIds: [roundId]
      })
    }
  }

  createCreditCheckout(gameId, roundId) {
    let url = ConfigConstants.API_URL_V2 + 'players/wallets/checkout'
    return this.http.post(url, {
      gameIds: [gameId], roundIds: [roundId], isCredit: true
    })
  }

  createSurvivorEarlyTicket(gameId, roundId) {
    let url = 'players/tickets'
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const affiliateId = Number(localStorage.getItem('AFF_ID'))
      return this.http.post(ConfigConstants.API_URL_V3 + url, { gameId, roundId, sessionId, affiliateId });
    } else {
      return this.http.post(ConfigConstants.API_URL_V2 + url, { gameId, roundId })
    }

  }

  createSurvivorRebuyCheckoutV2(ticketId:number, gameIds:number[], roundIds: number[]) {
    let url = ConfigConstants.API_URL_V2 + 'players/wallets/checkout/rebuy'
    return this.http.post(url, {
      ticketId, gameIds, roundIds
    })
  }

  getv2PlayerTickets() {
    let url = 'players/tickets?status=INPLAY&status=WINNER&status=LOST&paymentStatus=SUCCEEDED&paymentStatus=NOTATTEMPTED&status=NOSELECTION';
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getAllGames() {
    let url = 'games'
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  setDisplayName(id, displayName) {
    let url = 'players/' + id
    return this.http.put(ConfigConstants.API_URL_V2 + url, { displayName })
  }

  getTicketByPlayerId(ticketId, gameId, roundId, playerId, getAnyPlayersTickets?) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const sessionString = sessionId ? `&sessionId=${sessionId}` : ''
      let url;
      if (getAnyPlayersTickets) {
        url = ConfigConstants.API_URL_V3 + 'tickets/' + ticketId + '?gameId=' + gameId + '&roundId=' + roundId + '&playerId=' + playerId + '&showSelections=true' + sessionString
      } else {
        url = ConfigConstants.API_URL_V3 + 'players/tickets/' + ticketId + '?showSelections=true' + sessionString
      }
      return this.http.get(url)
    }

    let url = ConfigConstants.API_URL_V3 + 'tickets/' + ticketId + '?gameId=' + gameId + '&roundId=' + roundId + '&playerId=' + playerId + '&showSelections=true'
    return this.http.get(url)
  }


  getFavouriteTicketIds(roundId) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (hasToken) {
      let url = 'players/favourites/tickets?roundId=' + roundId
      return this.http.get(ConfigConstants.API_URL_V2 + url)
    }
  }

  getLeaderboards(gameId, roundId, poolId) {
    let url = ConfigConstants.API_URL_V3 + 'leaderboards' + '?gameId=' + gameId + '&roundId=' + roundId + "&poolId=" + poolId;
    return this.http.get(url)
  }

  getLeaderboardsWithSelections(roundId, poolId, currencyId) {
    let url = ConfigConstants.API_URL_V3 + 'rounds/' + roundId + '/leaderboardresults?selectionsSummary=true&poolId=' + poolId + "&currencyId=" + currencyId;
    return this.http.get(url)
  }

  getLeaderboardsByGameId(gameId) { 
    const url = ConfigConstants.API_URL_V3 + 'leaderboards?gameId=' + gameId
    return this.http.get(url)
  }

  getLeaderboardsByRoundId(roundId) {
    const url = ConfigConstants.API_URL_V3 + 'leaderboards?roundId=' + roundId
    return this.http.get(url)
  }

  getWinnerData(gameId) {
    let url = 'games/' + gameId + '/leaderboards?state=RESULTED&state=PAIDOUT'
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getFavouritePlayers(roundId) {
    const url = ConfigConstants.API_URL_V2 + 'players/favourites/participants?roundId=' + roundId;
    return this.http.get(url)
  }

  toggleFavouritePlayer(gameId, roundId, favParticipantId, sportId, isFavourite) {
    const url = ConfigConstants.API_URL_V2 + '/players/favourites/participants'
    return this.http.put(url, { gameId, roundId, favParticipantId, sportId, isFavourite })
  }

  getWallets() {
    const currencyId = ConfigConstants.CURRENCY_ID;
    this.http.get(ConfigConstants.API_URL_V2 + 'players/wallets').subscribe(res => {
      const wallet = res['data'].wallets.find(it => it['currencyId'] === currencyId);
      this.userWallet.next(wallet.balance);
    });
  }

  depositIntoWallet(amount) {
    const url = 'players/wallets/deposit';
    return this.http.post(ConfigConstants.API_URL_V2 + url, { amount, currency: 'GBP' });
  }

  registerCard() {
    const url = 'players/wallets/registrations';
    return this.http.post(ConfigConstants.API_URL_V2 + url, null);
  }

  walletDepositStatus(checkoutId) {
    const url = 'players/wallets/deposit/status';
    return this.http.post(ConfigConstants.API_URL_V2 + url, { checkoutId: checkoutId, sportId: 2 });
  }

  walletRegisterStatus(checkoutId) {
    const url = 'players/wallets/registrations/status';
    return this.http.post(ConfigConstants.API_URL_V2 + url, { checkoutId: checkoutId });
  }

  completeCheckout(checkoutRef, isCredit, gameId, roundId) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/checkout/complete';
    return this.http.post(url, { checkoutRef, isCredit, gameId, roundId });
  }

  updatedPlayerDepositConsent(consent = true, playerId) {
    const url = ConfigConstants.API_URL_V2 + 'players/' + playerId;
    return this.http.put(url, { depositConsent: consent });
  }

  withdrawWalletAmount(amount) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/withdraw';
    return this.http.post(url, { amount });
  }

  getPlayerWithdrawalRequests(affId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/withdrawals?status=PENDING_APPROVAL&affiliateId='+affId;
    return this.http.get(url);
  }

  getPayoutsHistory(affId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/payouts?status=SUCCEEDED&affiliateId='+affId;
    return this.http.get(url);
  }

  getRefundsHistory(affId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/payouts?status=REFUNDED&affiliateId='+affId;
    return this.http.get(url);
  }

  getPurchaseHistory(affId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/purchases?status=SUCCEEDED&affiliateId='+affId;
    return this.http.get(url);
  }

  getDepositsHistory(affId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/deposits?status=SUCCESS&affiliateId='+affId;
    return this.http.get(url);
  }

  getWithdrawalHistory(affId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/withdrawals?affiliateId='+affId;
    return this.http.get(url);
  }

  toggleFavouriteTicket(ticketId, gameId, roundId, isFavourite) {
    let url = 'players/favourites/tickets/' + ticketId
    return this.http.put(ConfigConstants.API_URL_V2 + url, { gameId, roundId, isFavourite })
  }

  initiateCheckout(gameId, roundId) {
    let url = ConfigConstants.API_URL_V2 + 'players/wallets/checkout'
    return this.http.post(url, {
      gameIds: [gameId], roundIds: [roundId]
    })
  }

  playerSelfExclude(duration) {
    const url = 'players/exclusion/self'
    return this.http.post(ConfigConstants.API_URL_V2 + url, {
      duration
    })
  }

  playerSetDepositLimit(duration, amount) {
    const url = 'players/depositlimit';
    return this.http.put(ConfigConstants.API_URL_V2 + url, {
      duration, amount
    })
  }

  playerTimeoutLimit(duration) {
    const url = 'players/timeout';
    return this.http.put(ConfigConstants.API_URL_V2 + url, {
      duration
    })
  }

  getPastFixtureResults(eventId) {
    const url = 'ds/events/' + eventId + '/last-events-results'
    return this.http.get(ConfigConstants.API_URL_V3 + url)
  }

  getLbResultsByTicket(ticketId, poolId, currencyId) {
    const url = 'players/tickets/' + ticketId + '/leaderboardresults?poolId=' + poolId + "&currencyId=" + currencyId;
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  // V3 Endpoints

  getGames() {
    const url = ConfigConstants.API_URL_V3 + 'games?states=SETUP&states=INPROGRESS&states=REPORTED&states=RESULTED'
    return this.http.get(url)
  }

  getLobbyGames(affId: number) {
    const url = ConfigConstants.API_URL_V3 + 'pub/gs/lobbygames?state=INPROGRESS&state=RESULTED&state=REPORTED&state=CANCELLED&affiliateId='+affId
    return this.http.get(url)
  }

  getRoundById(roundId) {
    const url = ConfigConstants.API_URL_V3 + 'rounds?id=' + roundId
    return this.http.get(url)
  }

  getRoundByIdv2(roundId) {
    const url = ConfigConstants.API_URL_V2 + 'rounds?id=' + roundId
    return this.http.get(url)
  }

  getAllGamesV3() {
    const url = ConfigConstants.API_URL_V3 + 'games'
    return this.http.get(url)
  }

  getAllGamesFilteredByGameTypesV3(gameTypeIds: number[]) {
    let url = ConfigConstants.API_URL_V3 + 'games?';
    gameTypeIds.forEach((id, index) => {
      url += 'gameTypeId=' + id;
      if (index < gameTypeIds.length - 1) {
        url += '&';
      }
    })
    return this.http.get(url)
  }

  getGameById(gameId) {
    let url = ConfigConstants.API_URL_V3 + 'games?id=' + gameId
    return this.http.get(url)
  }

  getGameByIds(gameIds: any[]) {
    const queryParams = this.utilsService.encodeQueryParam("id", gameIds)
    let url = ConfigConstants.API_URL_V3 + 'games?' + queryParams
    return this.http.get(url)
  }

  getTicketsByGameId(gameId) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const sesionString = sessionId ? `&sessionId=${sessionId}` : ''
      let url = 'players/tickets' + '?gameId=' + gameId + '&showSelections=true' + sesionString;
      return this.http.get(ConfigConstants.API_URL_V3 + url)
    } else {
      let url = 'players/tickets' + '?gameId=' + gameId + '&showSelections=true';
      return this.http.get(ConfigConstants.API_URL_V2 + url)
    }
  }

  getTicketsByGameIdAndRoundId(gameId, roundId) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const sesionString = sessionId ? `&sessionId=${sessionId}` : ''
      let url = 'players/tickets' + '?gameId=' + gameId + '&roundId=' + roundId + '&showSelections=true' + sesionString;
      return this.http.get(ConfigConstants.API_URL_V3 + url)
    } else {
      let url = 'players/tickets' + '?gameId=' + gameId + '&roundId=' + roundId + '&showSelections=true';
      return this.http.get(ConfigConstants.API_URL_V2 + url)
    }
  }

  saveTicket(data) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    const url = 'players/tickets';
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const affiliateId = Number(localStorage.getItem('AFF_ID'))
      return this.http.post(ConfigConstants.API_URL_V3 + url, { ...data, sessionId, affiliateId });
    } else {
      return this.http.post(ConfigConstants.API_URL_V2 + url, data)
    }
  }

  createSurvivorRebuyTicket(payload) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'players/tickets/rebuy',payload)
  }

  createPick6Ticket(data) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    let url = 'players/tickets'
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const affiliateId = Number(localStorage.getItem('AFF_ID'))
      return this.http.post(ConfigConstants.API_URL_V3 + url, { ...data, sessionId, affiliateId });
    } else {
      return this.http.post(ConfigConstants.API_URL_V2 + url, data)
    }
  }

  createPick6Participants(ticketId, data) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    let url = 'players/tickets/' + ticketId + '/selections'
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      return this.http.put(ConfigConstants.API_URL_V3 + url, { ...data, sessionId });
    } else {
      return this.http.put(ConfigConstants.API_URL_V2 + url, data)
    }
  }

  saveEditTicket(ticketId, data) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    const url = 'players/tickets/' + ticketId + '/selections';
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      return this.http.put(ConfigConstants.API_URL_V3 + url, { ...data, sessionId });
    } else {
      return this.http.put(ConfigConstants.API_URL_V2 + url, data);
    }
  }

  fetchTicketNudges(ticketId, roundId) {
    const url = 'players/tickets/' + ticketId + '/nudges-summary?roundId=' + roundId;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  saveTicketNudges(ticketId, data) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    const url = 'players/tickets/' + ticketId + '/nudges';
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      return this.http.post(ConfigConstants.API_URL_V3 + url, { ...data, sessionId });
    } else {
      return this.http.post(ConfigConstants.API_URL_V2 + url, data);
    }
  }

  editTeamName(ticketId, data) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    const affiliateId = Number(localStorage.getItem('AFF_ID'));
    let url = 'players/tickets/' + ticketId
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      return this.http.put(ConfigConstants.API_URL_V3 + url, { ...data, sessionId, affiliateId })
    } else {
      return this.http.put(ConfigConstants.API_URL_V2 + url, data)
    }
  }

  getPlayerTickets(gameId, roundId) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    const url = 'players/tickets?gameId=' + gameId + '&roundId=' + roundId;
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const sesionString = sessionId ? `&sessionId=${sessionId}` : ''
      return this.http.get(ConfigConstants.API_URL_V3 + url + sesionString);
    } else {
      return this.http.get(ConfigConstants.API_URL_V2 + url);
    }
  }

  deleteTicket(ticketId) {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (!hasToken) {
      const sessionId = localStorage.getItem('sessionToken')
      const sesionString = sessionId ? `?sessionId=${sessionId}` : ''
      const url = 'players/tickets/' + ticketId + sesionString;
      return this.http.delete(ConfigConstants.API_URL_V3 + url)
    } else {
      const url = 'players/tickets/' + ticketId;
      return this.http.delete(ConfigConstants.API_URL_V2 + url)
    }
  }

  getTimeBucketSelections() {
    const hasToken = localStorage.getItem('JWT_TOKEN')
    const url = 'players/selections/timebuckets';
    if (!hasToken) {
      return this.http.get(ConfigConstants.API_URL_V3 + url)
    } else {
      return this.http.get(ConfigConstants.API_URL_V2 + url);
    }
  }

  // leaderboard endpoints
  public LEADERBOARD_POLLING_INTERVAL = ConfigConstants.LEADERBOARD_POLLING_INTERVAL;

  getResultsData(roundId) {
    let url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/horseraces'
    return this.http.get(url)
  }

  getTicketWithSelectionPoints(ticketId, roundId) {
    let url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/tickets/' + ticketId + '?currencyId=' + ConfigConstants.CURRENCY_ID+'&scoreNudges=true';
    return this.http.get(url)
  }

  getLiveRoundGolfParticipants(roundId) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/golfparticipants';
    return this.http.get(url)
  }

  getPointsBreakdown(roundId: number, ticketId: number) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/tickets/' + ticketId + '?currencyId=' + ConfigConstants.CURRENCY_ID;
    return this.http.get(url);
  }

  getLiveRoundFixtures(roundId) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/fixtures'
    return this.http.get(url)
  }

  getLiveRoundHeader(roundId) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/header'
    return this.http.get(url)
  }

  getLiveRoundQuestions(roundId) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/questions'
    return this.http.get(url)
  }

  getLiveRoundPoints(roundId: number) {
    const playerId = localStorage.getItem('PLAYER_ID')
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/players/' + playerId + '/tickets?currencyId=' + ConfigConstants.CURRENCY_ID
    return this.http.get(url)
  }

  getLeaderboardResultsByTicketIds(roundId, ticketIds, poolId) {
    let string = '?roundId=' + roundId;
    string += '&poolId=' + poolId;
    ticketIds.forEach(ticketId => {
      string = string + '&ticketId=' + ticketId;
    });
    const url = 'leaderboards/results/tickets' + string;
    return this.http.get(ConfigConstants.LEADERBOARD_SERVICE_URL + url);
  }

  getLeaderboardResults(roundId, poolId, currencyId) {
    const url = 'leaderboards/results?roundId=' + roundId + '&poolId=' + poolId + '&currencyId=' + currencyId + '&limit=' + ConfigConstants.SHORT_LEADERBOARD_RESULT_LIMIT;
    return this.http.get(ConfigConstants.LEADERBOARD_SERVICE_URL + url);
  }

  getFullLeaderboardResults(roundId, poolId, currencyId) {
    const url = 'leaderboards/results?roundId=' + roundId + '&poolId=' + poolId + '&currencyId=' + currencyId;
    return this.http.get(ConfigConstants.LEADERBOARD_SERVICE_URL + url);
  }

  getIncidents(roundId: number, dsEventId: string) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/incidents/' + dsEventId;
    return this.http.get(url);
  }

  getResultedIncidents(roundId: number) {
    const url = ConfigConstants.API_URL_V3 + 'incidents?roundId=' + roundId;
    return this.http.get(url);
  }

  getLiveSelectionPercentages(roundId: number) {
    const url = ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/tickets/overview';
    return this.http.get(url);
  }

  getSelectionPercentages(roundId: number) {
    const url = ConfigConstants.API_URL_V3 + 'rounds/' + roundId + '/tickets/overview';
    return this.http.get(url);
  }

  getUsefulStatsSGS(eventId: string) {
    const url = 'ds/events/' + eventId + '/stats';
    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  getDsmEvents() {
    let url = `ds/events`;
    let f = new Date();
    let g = new Date();
    f.setHours(0, 0, 0, 0);
    g.setHours(24, 0, 0, 0);

    let from = f.toISOString();
    let to = g.toISOString();
    url += `?from=${from}&to=${to}`

    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  setNotificationsSeen(nids) {
    const url = 'notification/update/seen'
    return this.http.put(ConfigConstants.API_URL_V2 + url, {
      nids
    })
  }

  setNotificationRead(nids) {
    const url = 'notification/update/read'
    return this.http.put(ConfigConstants.API_URL_V2 + url, {
      nids
    })
  }

  sendHeatmapInfo(heatmapPayload: any) {
    const url = 'heatmap';
    return this.http.put(ConfigConstants.API_URL_V3 + url, heatmapPayload)
  }

  createFullCheckout(gameIds:number[]) {
    const url = ConfigConstants.API_URL_V2 + 'players/wallets/checkout'
    const hasToken = localStorage.getItem('JWT_TOKEN')
    if (!hasToken) {
      console.log("No token")
    } else {
      return this.http.post(url, { gameIds })
    }
  }
  getGameReports(query: any) {
    let url = 'games/reports';
    const queryString = this.buildQueryString(query);
    if (queryString.length > 0) {
      url += '?' + queryString;
    }
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getAffiliatesReports(query: any) {
    let url = 'affiliates/reports';
    const queryString = this.buildQueryString(query);
    if (queryString.length > 0) {
      url += '?' + queryString;
    }
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getAffiliatesReportUtms(id) {
    const url = 'affiliates/reports/' + id + '/utms';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  buildQueryString(queryObj) {
    let queryString = '';
    Object.keys(queryObj).forEach(key => {
      if (queryString.length > 0) {
        queryString += '&';
      }
      queryString += key + '=' + queryObj[key];
    })
    return queryString;
  }


  completeFullCheckout(basketCheckoutId: number, referralCode: string) {
    const url = ConfigConstants.API_URL_V2 + `players/wallets/checkout/complete`;
    return this.http.post(url, {
      basketCheckoutId,
      referralCode
    });
  }

  getPlayerWallets() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'players/wallets')
  }

  getOffers() {
    const affiliateId = Number(localStorage.getItem('AFF_ID'))
    const url = ConfigConstants.API_URL_V3 + 'offers?affiliateId=' + affiliateId;
    return this.http.get(url);
  }

  getPlayerPaymentTokens() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'players/payment-tokens')
  }

  updatePlayerPaymentTokens(id) {
    return this.http.put(ConfigConstants.API_URL_V2 + 'players/payment-tokens/'+id, null)
  }

  deletePlayerPaymentTokens(id) {
    return this.http.delete(ConfigConstants.API_URL_V2 + 'players/payment-tokens/'+id)
  }

  getPromos() {
    return this.http.get(ConfigConstants.API_URL_V3 + 'promos')
  }

  getPlayerStatsByIds(playerIds: any[], tournamentSk: string) {
    const queryParams = this.utilsService.encodeQueryParam("playerCid", playerIds)
    const url = ConfigConstants.API_URL_V3 + 'ds/tournaments/' + tournamentSk + '/playerStats?' +  queryParams
    return this.http.get(url)
  }

  submitFeedback(feedback) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'games/feedback', feedback)
  }

  updateFeedback(id, feedback) {
    return this.http.put(ConfigConstants.API_URL_V2 + 'games/feedback/' + id, feedback)
  }

  getGameFeedbacks() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'games/feedback')
  }

  getOtmAssetByDsid(dsId: string) {
    return this.http.get(ConfigConstants.API_URL_V3 + 'ds/events/' + dsId + "/assets")
  }

  saveSNLTicketNudges(ticketId, roundId, ticketData) {
    const url =  ConfigConstants.LEADERBOARD_SERVICE_URL + 'liverounds/' + roundId + '/tickets/' + ticketId + '?currencyId=' + ConfigConstants.CURRENCY_ID + '&scoreNudges=true';
    return this.http.post(url, ticketData); 
  }

  getPlayerReferrals(referralId) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'playerreferrals?referralId=' + referralId)
  }

  getPlayerReferral(referralCode) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'playerreferrals/' + referralCode)
  }

  createPlayerReferralLink(playerReferral) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'playerreferrals', playerReferral)
  }
}


