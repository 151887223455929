import { HttpClient } from "@angular/common/http";
import { Injectable, computed, signal } from "@angular/core";
import { ApiService } from "../../api-service/api.service";
import { ContentfulService, UtilsService } from "projects/fantasy-shared/src/services";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfigConstants } from "config-constants";

@Injectable({
    providedIn: 'root'
})
export class AppStore {
    constructor(private apiService: ApiService, private utilsService: UtilsService, private spinner: NgxSpinnerService, private contentfulService: ContentfulService) { }

    public games = signal([])
    public rounds = signal([])
    public lobbygames = signal([])
    public tickets = signal([])
    public isAuthenticated = signal(false)
    public basketViewState = signal(false)
    public activeTab = signal('home');
    public activeGameFilter = signal('All');
    public activeGameTypeFilter = signal('All');
    public nonBottomNavFlow = signal(false)
    public wallets = signal([])
    public isDesktop = signal(false);
    public playerDetails: any = signal(null);
    public affName = signal("");
    public forceMarketingPreferences = signal(false);
    public securePassword = signal(true);
    public showNotifications = signal(false);
    public setDisplayName = signal(false);
    public lobbyDetailType = signal("");
    public playerNotifications = signal([])
    public leaderboards = signal({})
    public finalLeaderboards = signal({})
    public roundIncidents = signal({})
    public myGamesAlertHR = signal(null)
    public myGamesAlertSURV = signal(null)
    public myGamesAlertBasketballSURV = signal(null)
    public myGamesAlertIceHockeySURV = signal(null)
    public myGamesAlertReferralTicketWithoutSelections = signal(null)
    public gameParticipants = signal({})
    public golfParticipants = signal({})
    public big5LiveFixtures = signal({})
    public big5Fixtures = signal({})
    public big5Deciders = signal({})
    public liveRaces = signal({})
    public horseRacingOpenGames = signal({})
    public horseRacingOpenRounds = signal({})
    public footballSurvivorOpenGames = signal({})
    public footballSurvivorOpenRounds = signal({})
    public horseRacingnonRunnerAlert = signal({})
    public gameCmsMetas = signal([])
    public allGameIds = signal([])
    public offers = signal([])
    public gameFeedbacks = signal([])
    public velocityTrackers = signal([])
    public entryAddedSuccess = signal(false)
    public lobbyLoaded = signal(false)
    public liveGamesHeader = signal({})
    public otmAssets = signal({})

    public screenWidth = signal(0)
    public earlyBirdPromoCheckoutGameId = signal(0)
    basketFlowSteps = [
        'unpaid',
        'deposit',
        'depositStatus',
        'checkout',
        'checkoutStatus'
    ]
    public basketFlowStep = signal('unpaid')

    async fetchGameRoundTickets() {
        const { lobbyGames } = (await this.getLobbyGames());
        const games = lobbyGames.filter(lg => {
            const round = this.utilsService.getRoundFromRoundsSummary(lg);
            return round.state === 'EARLY' || round.state === 'OPEN' || round.state === 'LIVE' || round.state === 'RESULTED' || lg.state === 'REPORTED' || lg.state === 'RESULTED' || round.state === 'CANCELLED' || lg.state === 'CANCELLED';
        });
        this.games.set(games)
        const selectedGameIds = this.games().map(gm => gm.id)
        this.allGameIds.set(selectedGameIds)
        this.updateIsAuthenticated()
        await this.refreshTickets()
        await this.fetchLeaderboards()
        await this.fetchFinalLeaderboards()
        await this.fetchIncidents()
        await this.fetchParticipants()
        await this.fetchGolfParticipants()
        await this.fetchBig5Fixtures()
        await this.fetchBig5LiveFixtures()
        await this.fetchHorseracingGameData()
        await this.fetchLiveRaces()
        await this.fetchLiveHeaders()
        await this.getGameCmsMeta()
        await this.getOffers()
        await this.getGameFeedbacks()
        await this.getOtmAssets()
        setInterval(async () => {
            await this.fetchLeaderboards()
            await this.fetchIncidents()
            await this.fetchBig5LiveFixtures()
            await this.fetchLiveRaces()
            await this.fetchLiveHeaders()
        }, 60000)
    }

    updateIsAuthenticated(){
       this.isAuthenticated.set(localStorage.getItem('PLAYER_ID') != null)
    }

    async refreshTickets(){
        const { tickets } = await this.getTickets(this.allGameIds(), true)
        this.tickets.set(tickets)
        this.spinner.hide();
        this.lobbyLoaded.set(true);
    }

    public unpaidGames = computed(() => {
        const unpaidTickets = this.tickets().filter(tkt => tkt.status == 'UNPAID')
        const uniqueGameIds = [...new Set(unpaidTickets.map(t => t.gameId))]
        const games = this.games().filter(gm => uniqueGameIds.includes(gm.id))
        games.map(gm => {
            gm['unpaidTickets'] = unpaidTickets.filter(t => t.gameId === gm.id)
        })
        return games;
    })

    public unpaidTicketsLength = computed(() => {
        const unpaidTickets = this.tickets().filter(tkt => tkt.status == 'UNPAID')
        return unpaidTickets.length || 0;
    })

    public paidGames = computed(() => {
        const paidTickets = this.tickets().filter(tkt => tkt.paymentStatus !== 'NOTATTEMPTED' && tkt.status !== 'LOST' )
        const paidLostTickets = this.tickets().filter(tkt => tkt.paymentStatus === 'SUCCEEDED' && tkt.status === 'LOST' )
        const paidBonusTickets = this.tickets().filter(tkt => tkt.ticketType === 'BONUS' )
        const allPaidTickets = paidTickets.concat([...paidLostTickets, ...paidBonusTickets]);
        const unpaidTickets = this.tickets().filter(tkt => tkt.status == 'UNPAID')
        const uniqueGameIds = [...new Set(allPaidTickets.map(t => t.gameId))]
        const games = this.games().filter(gm => uniqueGameIds.includes(gm.id))
        games.map(gm => {
            gm['paidTickets'] = [...allPaidTickets.filter(t => t.gameId === gm.id)]
            gm['unpaidTickets'] = unpaidTickets.filter(t => t.gameId === gm.id)
        });
        return games;
    })

    public liveGames = computed(() => {
        // const approvedGameTypeNames = ['SINGLE GAME SURVIVOR', 'SINGLE GAME SURVIVOR:GS', 'HORSE RACING', 'FOOTBALL SURVIVOR', 'FOOTBALL SURVIVOR:GS', 'FOOTBALL SHORT FORMAT', 'FOOTBALL SHORT FORMAT:GS', 'HORSE RACE FIB UK', 'HORSE RACE FIB UK:GS']
        const approvedGames = this.games()
        const liveGames = approvedGames.filter(g => g.roundsSummary.find(r => r.state === 'LIVE') && ['singlegamesurvivor', 'fantasygolf', 'shoutracing'].includes(this.utilsService.getGameTypeName(g)))
        const uniqueGameIds = [...new Set(liveGames.map(t => t.id))]
        const tickets = this.tickets().filter(tkt => uniqueGameIds.includes(tkt.gameId))

        liveGames.map(gm => {
            gm['tickets'] = tickets.filter(t => t.gameId === gm.id)
        })
        return liveGames
    })


    public openGamesLength = computed(() => {
        const paidTickets = this.tickets().filter(tkt => tkt.paymentStatus !== 'NOTATTEMPTED' && tkt.status !== 'LOST')
        const paidLostTickets = this.tickets().filter(tkt => tkt.paymentStatus === 'SUCCEEDED' && tkt.status === 'LOST' )
        const paidBonusTickets = this.tickets().filter(tkt => tkt.ticketType === 'BONUS' )
        const allPaidTickets = paidTickets.concat([...paidLostTickets, ...paidBonusTickets]);
        const uniqueGameIds = [...new Set(allPaidTickets.map(t => t.gameId))]
        const games = this.games().filter(gm => uniqueGameIds.includes(gm.id) && (gm.roundsSummary.find(r => r.state === 'OPEN') || gm.roundsSummary.find(r => r.state === 'EARLY') || gm.roundsSummary.find(r => r.state === 'LIVE')))
        games.map(gm => {
            gm['paidTickets'] = allPaidTickets.filter(t => t.gameId === gm.id)
        })
        return games.length;
    })

    public lobbyGamesVisible = computed(() => {
        return (!this.showNotifications() && !this.forceMarketingPreferences() && this.securePassword())
    })

    async fetchLiveRaces() {
        const liveGames = this.games().filter(g => g.roundsSummary.find(r => r.state === 'LIVE') && this.utilsService.getGameTypeName(g) === 'shoutracing')
        const roundIds = [];
        liveGames.map(g => {
            const liveRound = g.roundsSummary.find(r => r.state === 'LIVE')
            if(liveRound) roundIds.push(liveRound.id)
        })

        const promises = []
        roundIds.map(roundId => {
            promises.push(this.getLiveRaces(roundId))
        })

        Promise.all(promises).then(res => {
            let liveRaces = {}
            roundIds.map((roundId, index) => {
                liveRaces = { ...liveRaces, [roundId]: res[index].liveRoundHorseRaces }
            })
            this.liveRaces.set(liveRaces)
        })
    }

    async fetchLeaderboards() {
        const liveGames = this.games().filter(g => g.roundsSummary.find(r => r.state === 'LIVE'))
        const roundIds = [];
        const roundPoolIds = {};
        liveGames.map(g => {
            const liveRound = g.roundsSummary.find(r => r.state === 'LIVE')
            if(liveRound) roundIds.push(liveRound.id)
            if (this.utilsService.getGameTypeName(g) === 'snakesandladders') {
                roundPoolIds[liveRound.id] = 1
            } else {
                if(liveRound['pools']) {
                    const pool = liveRound['pools'].find(p => p.currencyId === ConfigConstants.CURRENCY_ID)
                    if(pool) {
                        roundPoolIds[liveRound.id] = pool.id
                    } else roundPoolIds[liveRound.id] = 0
                } else {
                    roundPoolIds[liveRound.id] = 0
                }
            }
        })

        const promises = []
        roundIds.map(roundId => {
            promises.push(this.getLeaderboardResults(roundId, roundPoolIds[roundId], ConfigConstants.CURRENCY_ID))
        })

        Promise.all(promises).then(res => {
            let lb = {}
            roundIds.map((roundId, index) => {
                lb = { ...lb, [roundId]: res[index].leaderboardResults }
            })
            this.leaderboards.set(lb)
        })
    }

    async fetchFinalLeaderboards() {
        const finishedGames = this.games().filter(g => {
            const round = this.utilsService.getRoundFromRoundsSummary(g);
            return g.roundsSummary.find(r => r.state === 'RESULTED') || ((['footballsurvivor', 'basketballsurvivor', 'icehockeysurvivor'].includes(this.utilsService.getGameTypeName(g))) && round.roundNumber > 1)
        });
        const roundIds = [];
        const roundPoolIds = {};
        finishedGames.map(g => {
            let finishedRound = null
            if (['footballsurvivor', 'basketballsurvivor', 'icehockeysurvivor'].includes(this.utilsService.getGameTypeName(g))) {
                const round = this.utilsService.getRoundFromRoundsSummary(g)
                if (round.roundNumber > 1) {
                    const pools = [];
                    const resultedRoundIds = [];
                    g.roundsSummary.map(r => {
                        const pool = r?.pools?.find(p => p.currencyId === ConfigConstants.CURRENCY_ID)
                        if (pool) pools.push(pool)
                        if (r.state === 'RESULTED') resultedRoundIds.push(r.id)
                    })
                    const lastResultedPool = pools.filter(p => resultedRoundIds.includes(p.roundId) && p.remainingRakedPot === 0).sort((a, b) => a.roundId > b.roundId ? 1 : -1)[0]
                    if(lastResultedPool) {
                        finishedRound = g.roundsSummary.filter(r => r.id === lastResultedPool.roundId)
                    } else {
                        finishedRound = g.roundsSummary.filter(r => r.roundNumber === round.roundNumber-1)
                    }
                }
            } else {
                finishedRound = g.roundsSummary.filter(r => r.state === 'RESULTED')
            }
            if(finishedRound) {
                const round = this.utilsService.getRoundFromRoundsSummary(g)
                roundIds.push(...finishedRound.map(r => r.id));
                if(this.utilsService.getGameTypeName(g) === 'snakesandladders') {
                    finishedRound.map(r => roundPoolIds[r.id] = 0)
                } else if (['footballsurvivor', 'basketballsurvivor', 'icehockeysurvivor'].includes(this.utilsService.getGameTypeName(g))) {
                        finishedRound.map(r => {
                            if(r['pools']) {
                                const pool = r['pools'].find(p => p.currencyId === ConfigConstants.CURRENCY_ID)
                                if(pool){
                                    roundPoolIds[r.id] = pool.id
                                } else roundPoolIds[r.id] = 0
                            } else {
                                roundPoolIds[r.id] = 0
                            }
                        })
                } else {
                    if(round['pools']) {
                        const pool = round['pools'].find(p => p.currencyId === ConfigConstants.CURRENCY_ID)
                        roundPoolIds[finishedRound[0].id] = pool.id
                    } else {
                        roundPoolIds[finishedRound[0].id] = 0
                    }
                }
            }
        });
        const promises = []
        roundIds.map((roundId, index) => {
            promises.push(this.getFinalLeaderboardResults(roundId, roundPoolIds[roundId], ConfigConstants.CURRENCY_ID))
        })

        Promise.all(promises).then(res => {
            let lb = {}
            roundIds.map((roundId, index) => {
                lb = { ...lb, [roundId]: res[index].leaderboardResults }
            })
            this.finalLeaderboards.set(lb)
        })
    }

    async fetchIncidents() {
        const liveGames = this.games().filter(g => g.roundsSummary.find(r => r.state === 'LIVE') && ['singlegamesurvivor', 'sgsbasketball', 'snakesandladders'].includes(this.utilsService.getGameTypeName(g)))
        const roundIds = [];
        liveGames.map(g => {
            const liveRound = g.roundsSummary.find(r => r.state === 'LIVE')
            if(liveRound) roundIds.push(liveRound.id)
        })
        const promises = []
        roundIds.map(roundId => {
            const game = this.games().find(g => g.roundsSummary.find(r => r.id === roundId))
            const dsEventId =  game['fixtureSummary'][0].dsEventId
            promises.push(this.getIncidents(roundId, dsEventId))
        })

        Promise.all(promises).then(res => {
            let inc = {}
            roundIds.map((roundId, index) => {
                inc = { ...inc, [roundId]: res[index].liveRoundIncidents }
            })
            this.roundIncidents.set(inc)
        })
    }

    async fetchParticipants() {
        const liveGames = this.games().filter(g => g.roundsSummary.find(r => r.state === 'LIVE') && this.utilsService.getGameTypeName(g) === 'singlegamesurvivor')
        const gameIds = liveGames.map(lg => lg.id)
        if (gameIds.length === 0) {
            return
        }
        const promises = []
        promises.push(this.getGamesParticipants(gameIds))
        Promise.all(promises).then(res => {
            let pcpts = {}
            gameIds.map((gameId) => {
                const game = res[0].games.find(g => g.id === gameId);
                pcpts = { ...pcpts, [gameId]: game.participants }
            })
            this.gameParticipants.set(pcpts)
        })
    }

    async fetchBig5Fixtures() {
        const liveGames = this.games().filter(g => this.utilsService.getGameTypeName(g) === 'big5')
        const gameIds = liveGames.map(lg => lg.id)
        if (gameIds.length === 0) {
            return
        }
        const promises = []
        promises.push(this.getGamesParticipants(gameIds))
        Promise.all(promises).then(res => {
            let fixtures = {}
            let deciders = {}
            gameIds.map((gameId) => {
                const round = res[0].rounds.find(r => r.gameId === gameId);
                fixtures = { ...fixtures, [gameId]: round.fixtures}
                deciders = { ...deciders, [gameId]: round.deciders}
            })
            this.big5Fixtures.set(fixtures)
            this.big5Deciders.set(deciders)
        })
    }

    async fetchGolfParticipants() {
        const golfGames = this.games().filter(g => this.utilsService.getGameTypeName(g) === 'fantasygolf')
        const gameIds = golfGames.map(lg => lg.id)
        if (gameIds.length === 0) {
            return
        }
        const promises = []
        promises.push(this.getGamesParticipants(gameIds))
        Promise.all(promises).then(res => {
            let pcpts = {}
            gameIds.map((gameId) => {
                const round = res[0].rounds.find(r => r.gameId === gameId);
                pcpts = { ...pcpts, [gameId]: round.golfParticipants }
            })
            this.golfParticipants.set(pcpts)
        })
    }

    async fetchHorseracingGameData() {
        const openGames = this.games().filter(g => (g.roundsSummary.find(r => r.state === 'OPEN')))
        const gameIds = openGames.map(lg => lg.id)
        if (gameIds.length === 0) {
            return
        }
        const promises = []
        promises.push(this.getGamesParticipants(gameIds))
        Promise.all(promises).then(res => {
            let gameData = {}
            let roundData = {}
            let footballSurvivorGameData = {}
            let footballSurvivorRoundData = {}
            gameIds.map((gameId, index) => {
                const game = res[0].games.find(g => g.id === gameId);
                const round = res[0].rounds.find(r => r.gameId === gameId);
                const rounds = res[0].rounds.filter(r => r.gameId === gameId);
                const gamegame = openGames.find(g => g.id === gameId);
                if(['footballsurvivor', 'basketballsurvivor', 'icehockeysurvivor'].includes(this.utilsService.getGameTypeName(gamegame))){
                    footballSurvivorGameData = { ...footballSurvivorGameData, [gameId]: game}
                    footballSurvivorRoundData = { ...footballSurvivorRoundData, [gameId]: [...rounds]}
                } else if(this.utilsService.getGameTypeName(gamegame) === 'shoutracing') {
                    gameData = { ...gameData, [gameId]: game}
                    roundData = { ...roundData, [gameId]: round}
                }
            })
            const hrTickets = this.tickets().filter(tkt => {
                const game = openGames.find(g => g.id === tkt.gameId);
                const gameType = this.utilsService.getGameTypeName(game);
                return gameIds.includes(tkt.gameId) && tkt.paymentStatus === "SUCCEEDED" && gameType === 'shoutracing'
            })
            const survivorTickets = this.tickets().filter(tkt => {
                const game = openGames.find(g => g.id === tkt.gameId);
                const gameType = this.utilsService.getGameTypeName(game);
                return gameIds.includes(tkt.gameId) && tkt.paymentStatus === "SUCCEEDED" && ['footballsurvivor', 'basketballsurvivor', 'icehockeysurvivor'].includes(gameType)
            })
            const referralTickets = this.tickets().filter(tkt => {
                return gameIds.includes(tkt.gameId) && tkt.paymentStatus === "SUCCEEDED" && tkt.ticketType === 'REFERRAL'
            })
            let nonRunnerExists = false;
            let referralTicketWithoutSelectionsExists = false;
            let noSelectionsFootballExists = false;
            let noSelectionsBasketBallExists = false;
            let noSelectionsIceHockeyExists = false;
            let noSelectionsFootballExistsRound = 0;
            let noSelectionsBasketBallExistsRound = 0;
            let noSelectionsIceHockeyExistsRound = 0;
            hrTickets.map(htkt => {
                const ticketSelections = htkt.selections;
                ticketSelections?.map(ts => {
                    if(!nonRunnerExists){
                        const round = roundData[ts.gameId]
                        if(!round) return;
                        const races = round.horseMeetings.map(mt => mt.races || []).flat(Infinity);
                        const horses = races.map(mt => mt.horses || []).flat(Infinity);
                        const horse = horses.find(h => h.id === ts.roundHorseId)
                        nonRunnerExists = horse.status === 'NonRunner'
                    }
                })
            })
            survivorTickets.map(stkt => {
                const ticketGame = openGames.find(g => g.id === stkt.gameId);
                const ticketRound = this.utilsService.getRoundFromRoundsSummary(ticketGame);
                if(stkt.status === "NOSELECTION" && (!noSelectionsFootballExists || !noSelectionsBasketBallExists || !noSelectionsIceHockeyExists) && ticketRound.state === 'OPEN') {
                    if(this.utilsService.getGameTypeName(ticketGame) === 'basketballsurvivor') {
                        noSelectionsBasketBallExists = true
                        noSelectionsBasketBallExistsRound = ticketRound.roundNumber
                    }
                    else if(this.utilsService.getGameTypeName(ticketGame) === 'icehockeysurvivor') {
                        noSelectionsIceHockeyExists = true
                        noSelectionsIceHockeyExistsRound = ticketRound.roundNumber
                    }
                    else if(this.utilsService.getGameTypeName(ticketGame) === 'footballsurvivor') {
                        noSelectionsFootballExists = true
                        noSelectionsFootballExistsRound = ticketRound.roundNumber
                    }
                }
            })
            referralTickets.map(stkt => {
                const ticketGame = openGames.find(g => g.id === stkt.gameId);
                const ticketRound = this.utilsService.getRoundFromRoundsSummary(ticketGame);
                if(stkt.status === "NOSELECTION"  && !referralTicketWithoutSelectionsExists && ticketRound.state === 'OPEN') {
                    referralTicketWithoutSelectionsExists = true;
                }
            })
            if(nonRunnerExists) {
                this.myGamesAlertHR.set({
                    message: "One of your entries has a non-runner. There's still time to change it."
                })    
            } else {
                this.myGamesAlertHR.set(null)    
            }
            if(noSelectionsFootballExists || noSelectionsBasketBallExists || noSelectionsIceHockeyExists) {
                if (noSelectionsFootballExists) {
                    if (noSelectionsFootballExistsRound === 1) {
                        this.myGamesAlertSURV.set({
                            message: "Football Survivor Round 1 is now open. Pick your team!"
                        })
                    } else {
                        this.myGamesAlertSURV.set({
                            message: "You've made it to the next round in Football Survivor. Pick your next team!"
                        })
                    }
                }
                if (noSelectionsBasketBallExists) {
                    if (noSelectionsBasketBallExistsRound === 1) {
                        this.myGamesAlertBasketballSURV.set({
                            message: "Basketball Survivor Round 1 is now open. Pick your team!"
                        })
                    } else {
                        this.myGamesAlertBasketballSURV.set({
                            message: "You've made it to the next round in Basketball Survivor. Pick your next team!"
                        })
                    }
                }
                if (noSelectionsIceHockeyExists) {
                    if (noSelectionsIceHockeyExistsRound === 1) {
                        this.myGamesAlertIceHockeySURV.set({
                            message: "Ice Hockey Survivor Round 1 is now open. Pick your team!"
                        })
                    } else {
                        this.myGamesAlertIceHockeySURV.set({
                            message: "You've made it to the next round in Ice Hockey Survivor. Pick your next team!"
                        })
                    }
                }
            } else {
                this.myGamesAlertSURV.set(null)    
            }
            if (referralTicketWithoutSelectionsExists) {
                this.myGamesAlertReferralTicketWithoutSelections.set({
                    message: "Pick needed for your referral bonus entry!"
                })
            } else {
                this.myGamesAlertReferralTicketWithoutSelections.set(null)
            }
            this.horseRacingOpenGames.set(gameData)
            this.horseRacingOpenRounds.set(roundData)
            this.footballSurvivorOpenGames.set(footballSurvivorGameData)
            this.footballSurvivorOpenRounds.set(footballSurvivorRoundData)
        })
    }

    async fetchBig5LiveFixtures() {
        const liveGames = this.games().filter(g => g.roundsSummary.find(r => r.state === 'LIVE') && this.utilsService.getGameTypeName(g) === 'big5')
        const gameIds = liveGames.map(lg => lg.id)
        const promises = []
        gameIds.map(gameId => {
            const round = liveGames.find(g => g.id === gameId).roundsSummary[0]
            promises.push(this.getLiveFixtures(round.id))
        })
        Promise.all(promises).then(res => {
            let liveFixtures = {}
            gameIds.map((gameId, index) => {
                liveFixtures = { ...liveFixtures, [gameId]: res[index].liveRoundFixtures}
            })
            this.big5LiveFixtures.set(liveFixtures)
        })
    }

    async updateBasketTickets(gameId: number) {
        const gameIds = [gameId];
        const gameTicketsBefore = this.tickets().filter(t => t.gameId === gameId)
        const { tickets } = await this.getTickets(gameIds, true);
        const existingTickets = this.tickets().filter(t => t.gameId !== gameId)
        this.tickets.set([...existingTickets, ...tickets]);

        if (gameTicketsBefore.length < tickets.length) {
            this.entryAddedSuccess.set(true);
        }
    }

    showEntryAddedSuccess(value: boolean) {
        this.entryAddedSuccess.set(value);
    }

    async checkLobbyVisible() {
        this.affName.set(localStorage.getItem("AFF_NAME"));
        const playerDetails = await this.getPlayerDetails();
        this.playerDetails.set(playerDetails)
        const { smsUpdatesUpdatedAt, smsUpdates, securePassword } = playerDetails.playerFields;
        const defaultTime = "0001-01-01T00:00:00Z";
        if (smsUpdatesUpdatedAt === defaultTime && !smsUpdates) {
            this.forceMarketingPreferences.set(true)
        }
        this.securePassword.set(securePassword);
        await this.getNotifications();
        if (this.showNotifications()) {
            this.lobbyDetailType.set("NOTIFICATION")
        } else if (!this.showNotifications() && this.forceMarketingPreferences()) {
            this.lobbyDetailType.set("MARKETING")
        } else if (!this.showNotifications() && !this.forceMarketingPreferences() && !this.securePassword()) {
            this.lobbyDetailType.set("PASSWORD")
        } else {
            this.lobbyDetailType.set("")
        }
    }

    async fetchLiveHeaders() {
        const liveGames = this.games().filter(g => g.roundsSummary.find(r => r.state === 'LIVE'))
        const roundIds = [];
        liveGames.map(g => {
            const liveRound = g.roundsSummary.find(r => r.state === 'LIVE')
            if(liveRound) roundIds.push(liveRound.id)
        })

        const promises = []
        roundIds.map(roundId => {
            promises.push(this.getLiveHeaders(roundId))
        })

        Promise.all(promises).then(res => {
            let liveHeaders = {}
            roundIds.map((roundId, index) => {
                liveHeaders = { ...liveHeaders, [roundId]: res[index].liveRoundHeader }
            })
            this.liveGamesHeader.set(liveHeaders)
        })
    }

    getNotifications() {
        return new Promise((resolve, reject) => {
            const playerId = parseInt(localStorage.getItem("PLAYER_ID"), 10);
            this.apiService.getPlayerNotifications(playerId).subscribe((res: any) => {
                const playerNotifications = res.data.filter((it) => !it.seen);
                this.playerNotifications.set(playerNotifications)
                if (playerNotifications.length > 0) this.showNotifications.set(true);
                else this.showNotifications.set(false);
                resolve(res.data);
            },
                (err) => {
                    reject(err);
                    console.log(err);
                });
        })
    }

    getPlayerDetails(): any {
        return new Promise((resolve, reject) => {
            const playerId = localStorage.getItem("PLAYER_ID");
            if (!playerId) {
                return;
            }
            this.apiService.getPlayerDetails(playerId).subscribe(
                (res: any) => {
                    const journeyComplete =
                        res['data'].playerFields.journeyComplete;
                    const displayName =
                        res['data'].displayName
                    if (!journeyComplete) {
                        localStorage.removeItem("JOURNEY_COMPLETE");
                    }

                    if(!displayName) {
                        this.setDisplayName.set(true);
                    }
                    resolve(res.data);
                },
                (err) => {
                    reject(err);
                    console.log(err);
                }
            );
        });
    }


    async getOtmAssets() {
        const otmGames = this.games().filter(g => this.utilsService.getGameTypeName(g) === 'onthemoney')
        const dsIds = [];
        otmGames.map(g => {
            if(g.fixtureSummary[0].dsEventId) dsIds.push(g.fixtureSummary[0].dsEventId)
        })
        const uniqueDsIds = [...new Set(dsIds.map(t => t))]
        const promises = []
        uniqueDsIds.map(dsId => {
            promises.push(this.getOtmAsset(dsId))
        })
        Promise.all(promises).then(res => {
            let assets = {}
            uniqueDsIds.map((dsId) => {
                assets = { ...assets, [dsId]: res.find(r => r.eventDsId === dsId) }
            })
            this.otmAssets.set(assets)
        })
    }

    getGameCmsMeta() {
        return new Promise((resolve, reject) => { 
            this.contentfulService.getGameMeta().then(res => {
                this.gameCmsMetas.set(res.items)
                resolve(res.items)
            }, err => reject(err))
        })
    }

    getIncidents(roundId: number, dsEventId: string) :any {
        return new Promise((resolve, reject) => {
            this.apiService.getIncidents(roundId, dsEventId).subscribe(res => {
                if(res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getLeaderboardResults(roundId: number, poolId: number, currencyId: number) :any {
        return new Promise((resolve, reject) => {
            this.apiService.getFullLeaderboardResults(roundId, poolId, currencyId).subscribe(res => {
                if(res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getFinalLeaderboardResults(roundId: number, poolId: number, currencyId: number) :any {
        return new Promise((resolve, reject) => {
            this.apiService.getLeaderboardsWithSelections(roundId, poolId, currencyId).subscribe(res => {
                if(res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getLobbyGames(): any {
        return new Promise((resolve, reject) => {
            const affId = Number(localStorage.getItem("AFF_ID") || "1")
            this.apiService.getLobbyGames(affId).subscribe(res => {
                if (res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getGames(): any {
        return new Promise((resolve, reject) => {
            this.apiService.getGames().subscribe(res => {
                if (res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getGamesParticipants(gameIds: number[]): any {
        return new Promise((resolve, reject) => {
            this.apiService.getGameByIds(gameIds).subscribe(res => {
                if (res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getTickets(gameIds: number[], showSelections=false): any {
        return new Promise((resolve, reject) => {
            this.apiService.getTickets(gameIds, showSelections).subscribe(res => {
                if (res['errorCode'] === 0) {
                    resolve(res['data']);
                }
            })
        })
    }

    getLiveFixtures(roundId: number): any {
        return new Promise((resolve, reject) => {
            this.apiService.getLiveRoundFixtures(roundId).subscribe(res => {
                if (res['errorCode'] === 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getOffers() { 
        return new Promise((resolve, reject) => {
            this.apiService.getOffers().subscribe(res => {
                // if (res['errorCode'] === 0) {
                this.offers.set(res['data']?.offers);
                    resolve(res['data'])
                // }
            })
        })
    }

    getGameFeedbacks() { 
        return new Promise((resolve, reject) => {
            this.apiService.getGameFeedbacks().subscribe(res => {
                // if (res['errorCode'] === 0) {
                this.gameFeedbacks.set(res['data']);
                    resolve(res['data'])
                // }
            })
        })
    }


    // this.apiService.getLiveRoundFixtures(liveRoundId).subscribe(res => {
    //     this.liveRoundFixtures = res['data'].liveRoundFixtures;
    //   })

    getWallets() {
        return new Promise((resolve, reject) => {
            this.apiService.getPlayerWallets().subscribe(res => {
                if (res['errorCode'] == 0) {
                    resolve(res['data'])
                    this.wallets.set(res['data'].wallets)
                    this.updateIsAuthenticated()
                }
            })
        })
    }

    getLiveRaces(roundId: number) {
        return new Promise((resolve, reject) => {
            this.apiService.getResultsData(roundId).subscribe(res => {
                if (res['errorCode'] == 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getLiveHeaders(roundId: number) {
        return new Promise((resolve, reject) => {
            this.apiService.getLiveRoundHeader(roundId).subscribe(res => {
                if (res['errorCode'] == 0) {
                    resolve(res['data'])
                }
            })
        })
    }

    getOtmAsset(dsId: string) {
        return new Promise((resolve, reject) => {
            this.apiService.getOtmAssetByDsid(dsId).subscribe(res => {
                if (res['errorCode'] == 0) {
                    resolve(res['data'])
                }
            })
        })
    }

}
